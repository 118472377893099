import React, { useContext } from "react"
import { graphql, navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/MainLayout"
import Seo from "../components/WebSeo"
import * as styles from "./about.module.scss"
import Button from "../components/common/Button"
import { globalStateContext } from "../context/GlobalContextProvider"

const About = ({ data }) => {
  const banner = getImage(data.banner.childImageSharp.gatsbyImageData)
  const textData = data.textData
  const state = useContext(globalStateContext)
  const { language } = state
  const isEnglish = language === "EN"
  return (
    <Layout>
      <Seo title="About" />
      <section className={styles.container}>
        <div className={styles.hero}>
          <h3 className={styles.about}>
            {isEnglish ? textData.title[0] : textData.title[1]}
          </h3>
          <h1> {isEnglish ? textData.services[0] : textData.services[1]}</h1>
          <p>
            {isEnglish
              ? textData.servicesDescription[0]
              : textData.servicesDescription[1]}
          </p>
          <h1>
            {isEnglish
              ? textData.responsibility[0]
              : textData.responsibility[1]}
          </h1>
          <p>
            {isEnglish
              ? textData.responsibilityDescription[0]
              : textData.responsibilityDescription[1]}
          </p>
          <h1>{isEnglish ? textData.goal[0] : textData.goal[1]}</h1>
          <p>
            {isEnglish
              ? textData.goalDescription[0]
              : textData.goalDescription[1]}
          </p>
        </div>
        <div className={styles.image}>
          <GatsbyImage image={banner} alt="ABOUT US" />
        </div>
      </section>
      <div className={styles.buttonContainer}>
        <Button back="light" onClick={() => navigate("/contact")}>
          {isEnglish ? " get in touch" : "entrer en contact"}
        </Button>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query aboutJsonData {
    banner: file(relativePath: { eq: "team.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    textData: aboutJson {
      title
      services
      servicesDescription
      responsibility
      responsibilityDescription
      goal
      goalDescription
    }
  }
`

export default About
